
import DymaicRules from "./hook/index";
import AnalyzePostalCode from "./hook/analyze-postalcode.js"
import {
    ref
} from "vue";
export default {
    props: {
        // 修改回显的数据
        data: Object,
        /* 是否需要国家名称 */
        needCName: {
            type: Boolean,
            default: false
        },
        needRules: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        data(val) {
            if (val) {
                this.form = Object.assign({}, this.data);
            }
        }
    },
    setup(props, {
        emit
    }) {
        const formRef = ref();
        // 表单数据
        const form = ref(Object.assign({}, props.data));

        /**
         * 重置
         */
        function resetFields() {
            formRef.value.resetFields();
        }
        /*  */
        function validate() {
            emit("update:data", form.value);
            return formRef.value.validate();
        }

        const dymaicRules = DymaicRules(formRef, form);

        const analyzePostalCode = AnalyzePostalCode({
            address_form: formRef,
            address: form,
            rules: dymaicRules.rules
        });

        const handlePhone = (e) => {
            form.value.phone_number = e.target.value.replace(/[+|-]/g, "");
        }

        return {
            form,
            formRef,
            resetFields,
            validate,

            ...analyzePostalCode,
            ...dymaicRules,

            handlePhone
        };
    }
};
